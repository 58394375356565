import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../Header/index';
import Footer from '../../footer';
import Layout from '../../layout';
import Seo from '../../seo';
import { getPreviewPage } from '../../../state/app';
import ContentfulVetsArticle from '../../BlockLibrary/Veterinarians/ContentfulVetsArticle';
import { SLUG_VET_HOMEPAGE } from '../../utilities';

const VetsArticle = ({ article, isLoading }) => {
     const articlePreview = article.fields;
     const seoTitleTag = articlePreview.seoTitleTag ? articlePreview.seoTitleTag : `${articlePreview.breadcrumbValue}`;
     const seoMetaDescription = articlePreview.seoMetaDescription ? articlePreview.seoMetaDescription : articlePreview.shortDescription;

     return (
          <>
               <Layout>
                    <Seo
                         title={seoTitleTag}
                         canonical={articlePreview.seoCanonicalUrl}
                         description={seoMetaDescription}
                         robots={articlePreview.robotsDirective}
                         image={articlePreview.socialImage ? articlePreview.socialImage : articlePreview.featuredImage}
                    />
                    {isLoading && <div className="page-loading">Loading ...</div>}
                    <Header specificPhone={articlePreview.specificPhone} header={articlePreview.header}></Header>
                    <main id="main-content" role="main">
                         <section className="article-page">
                              <div className="container">
                                   <div className="article-page-breadcrumb">
                                        <a href="#">Home</a>/
                                        <a href={SLUG_VET_HOMEPAGE + 'events'}>Events</a>/
                                        <span>{articlePreview.breadcrumbValue}</span>
                                   </div>
                                   <ContentfulVetsArticle article={article} />
                              </div>
                         </section>
                    </main>
                    <Footer footer={articlePreview.footer}></Footer>
               </Layout>
          </>
     );
};

VetsArticle.propTypes = {
     article: PropTypes.object
};

function mapStateToProps(state) {
     return {
          article: getPreviewPage(state)
     };
}

export default connect(mapStateToProps)(VetsArticle);
