import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsPreviewStatus } from '../../../state/app';
import VetsArticleAuthor from './VetsArticleAuthor';
import { RichText } from '../../richTextOptions';
import VideoJSApp from '../Common/VideoJSApp';
import dayjs from 'dayjs';
import ShareSocials from '../Common/ShareSocials';

const VetsArticle = ({ article, isPreview }) => {
     const { articleTitle, contentType, topics, author, createdAt, body, downloadArticle, vetsEvenLongDescription, vetsVideo, vetsEventName, vetsEventDate } = isPreview
          ? article.fields
          : article;
     const articleContentType = contentType;
     const articleTopics = topics;

     const vetsEventDateFormat = vetsEventDate ? dayjs(vetsEventDate).format('MMMM D, YYYY h:mm A') : '';
     const featuredImage = article.featuredImage?.file.url;
     return (
          <>
               {vetsEventName && (
                    <div className="article-page-title">
                         <h1>{vetsEventName}</h1>
                         <p>{vetsEventDateFormat}</p>
                    </div>
               )}
               {articleTitle && (
                    <div className="article-page-title">
                         <h1>{articleTitle}</h1>
                         <p>{articleContentType || articleTopics}</p>
                    </div>
               )}
               {author && (
                    <VetsArticleAuthor
                         author={isPreview ? author.fields : author}
                         articleCreatedAt={isPreview ? article.sys.createdAt : createdAt}
                         downloadArticle={downloadArticle}
                    />
               )}
               {vetsVideo && <VideoJSApp urlVideo={vetsVideo.file.url} />}
               {body && (
                    <div className="article-page-body">
                         <RichText data={body} />
                    </div>
               )}
               {vetsEvenLongDescription && (
                    <div className="article-page-body">
                         <RichText data={vetsEvenLongDescription} />
                    </div>
               )}
               <ShareSocials titleShare="Share this article on" imageArticleUrl={featuredImage} />
          </>
     );
};

const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     };
};

VetsArticle.propTypes = {
     isPreview: PropTypes.bool
};

const ContentfulVetsArticle = connect(mapStateToProps)(VetsArticle);

export default ContentfulVetsArticle;
