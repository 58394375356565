import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsPreviewStatus } from '../../../state/app';
import dayjs from 'dayjs';

const Author = ({ author, articleCreatedAt, downloadArticle }) => {
     const handleClick = () => {
          window.open(downloadArticle.file.url, '_blank');
     };

     const articleCreatedAtFormat = articleCreatedAt ? dayjs(articleCreatedAt).format('MMMM D, YYYY') : '';

     return (
          <div className="article-page-author">
               <div
                    className="article-page-author-avatar"
                    style={{
                         backgroundImage: `url(${author.avatar?.file?.url ? author.avatar.file.url : author.avatar.fields.file.url})`,
                         width: '55px',
                         height: '55px'
                    }}
               ></div>
               <div className="article-page-author-name">
                    <p>{author.name}</p>
                    <span>{articleCreatedAtFormat}</span>
               </div>
               {downloadArticle && (
                    <div className="article-page-author-download">
                         <button onClick={handleClick}><span>Download Now</span></button>
                    </div>
               )}
          </div>
     );
};

const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     };
};

Author.propTypes = {
     isPreview: PropTypes.bool
};

const VetsArticleAuthor = connect(mapStateToProps)(Author);

export default VetsArticleAuthor;
